import React from 'react'
import { Link } from 'gatsby'
import './doccard.css'
import image from './prof.jpg'
const DocCard = ({ doctor }) => {
  return (
    <Link to={`/doctors/${doctor._id}`} className="link">
      <div className="searchItem">
        <img
          src={
            doctor.profileImage
              ? doctor.profileImage: image
              // : 'https://images.pexels.com/photos/5452255/pexels-photo-5452255.jpeg?auto=compress&cs=tinysrgb&w=1600'
          }
          alt={doctor.fullName}
          className="siImg"
        />
        <div className="siDesc">
          <h1 className="siTitle">{(doctor.title?doctor.title+" ":"") + doctor.firstName.charAt(0).toUpperCase() + doctor.firstName.slice(1)}{" "}{doctor.lastName.charAt(0).toUpperCase() + doctor.lastName.slice(1)}</h1>
          <span className="siSubtitle">{doctor.specialty.charAt(0).toUpperCase() + doctor.specialty.slice(1)}</span>
          {/* <span className="siTaxiOp">online</span>
          <span className="siDistance">500m from you</span> */}

          <span className="siCancelOp">County: {doctor.officeLocation.county.charAt(0).toUpperCase() + doctor.officeLocation.county.slice(1)}</span>
          <span className="siCancelOp">Street: {doctor.officeLocation.street.charAt(0).toUpperCase() + doctor.officeLocation.street.slice(1)}</span>
          <span className="siCancelOp">Building: {doctor.officeLocation.building.charAt(0).toUpperCase() + doctor.officeLocation.building.slice(1)}</span>
          {doctor.education?.length > 0 && <span className="siCancelOp">Qualifications: {doctor.education[0].qualification.charAt(0).toUpperCase() + doctor.education[0].qualification.slice(1)},{doctor.education[0].institution.charAt(0).toUpperCase() + doctor.education[0].institution.slice(1)} </span>}
          {/* <span className="siCancelOpSubtitle">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Possimus,
            adipisci. Ipsum, accusantium harum sapiente iste facere vero
            eligendi, eum veniam inventor
          </span> */}
        </div>
        <div className="siDetails">
          {/* <div className="siRating">
            <span>Excellent</span>
            <button>8.9</button>
          </div> */}
          <div className="siDetailTexts">
            <button className="siCheckButton">Book Appointment</button>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default DocCard
